import * as React from 'react';

export const MailerLiteEmbed = ({
  formUrl,
  width = '500px',
  height = '600px',
}) => {
  return (
    <iframe
      src={formUrl}
      style={{
        width,
        height,
        display: 'block',
        margin: 'auto',
        maxWidth: '100%',
      }}
      title='Sign up form'></iframe>
  );
};
