import * as React from 'react';

const LanguageQuiz = () => {
  const [stack, setStack] = React.useState(null);
  const [why, setWhy] = React.useState(null);
  const [experience, setExperience] = React.useState(null);
  const [otherExperience, setOtherExperience] = React.useState('');
  const [showResults, setShowResults] = React.useState(false);
  const setters = {
    stack: setStack,
    why: setWhy,
    experience: setExperience,
    otherExperience: setOtherExperience,
  };
  
  const recommendationsRef = React.useRef();
  
  const handleChange = (event) => {
    setShowResults(false);
    const target = event.target;
    const dataChanged = target.name.replace(/-\w/g, (match) => match.replace('-','').toUpperCase());
    let newValue = target.value;
    
    setters[dataChanged](newValue);

    if (newValue === 'other') {
      const otherTextField = event.target.parentNode.nextSibling.querySelector('[type="text"]');
      setTimeout(() => otherTextField.focus(), 0);
    }
    if (dataChanged === 'experience') {
      setOtherExperience('');
    }
  };
  
  const canSubmit = () => {
    return !!stack && !!why && !!experience;
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setShowResults(true);
    setTimeout(() => {
      recommendationsRef.current.scrollIntoView();
    }, 0);
  }
  
  const resultContent = {
    frontend: (<>
      <h3>Front-End: Learn HTML, CSS, and Javascript</h3>
      <p>Since browsers can only run HTML, CSS, and Javascript, you don't have a ton of choice here. Learn the languages in that order:</p>
      <ol>
        <li key="html">HTML</li>
        <li key="css">CSS</li>
        <li key="js">Javascript</li>
      </ol>
      <p><strong>HTML</strong> will allow you to define the structure of the page. You'll call out for the browser which text should be headings, which should be paragraphs, and which should be lists. You'll add images and tables of data if you need them. You'll define which words should be linked to other pages and which words should be displayed with additional emphasis.</p>
      <p><strong>CSS</strong> will define how each of the elements on your page will be displayed. What's the font size of a heading? What color should a link be? Should an image have a border? How wide should the image be? These questions and more will be answered by your CSS.</p>
      <p><strong>Javascript</strong> adds interactivity to your page. You could do a calculation when a user enters a number in a text field and display the result on the page (like a calculator). You could create a special link that, instead of taking the user to a different page, drops down a menu with some additional links. When a user enters their zip code, you might call out to another server that provides weather information and dynamically display the local weather for your user. Images and text are nice, but Javascript will make your sites come alive.</p>
    </>),
    frontendJavascriptExperience: (<>
      <p>Since you already have some experience with Javascript, you'll have a head start on this one. Fill in any gaps you have because knowing Javascript well opens up lots of new problems you can solve.</p>
    </>),
    xfrontend: (<>
      <p>If you decide later you want to become a full-stack developer, you can start learning Javascript for server-side code, or you can easily pick up another language to learn.</p>
    </>),
    backendNoExperience: (<>
      <h3>Back-End: Learn Javascript</h3>
      <p>For many years, Javascript was the language only of the browser, but today, servers can run Javascript too. Since you don't have any experience with a server-side language, learning Javascript allows you to double-dip since you will also be able to use it for front-end code.</p>
    </>),
    backendJavascriptExperience: (<>
      <h3>Back-End: Use Javascript</h3>
      <p>For many years, Javascript was the language only of the browser, but today, servers can run Javascript too. Learning Javascript allows you to double-dip since you will also be able to understand and write some front-end code.</p>
      <p>Since you already have Javascript experience — even if that's only on the front-end — that makes it even <em>more</em> of a home-run for you! </p>
    </>),
    backendOtherExperience: (<>
      <h3>Back-End: Use {otherExperience || experience}</h3>
      <p>If you already have experience with {otherExperience || experience} and you don't hate it, pick up wherever you are with it and keep going. That's your fastest path to being able to make cool stuff and {why}.</p>
    </>),
    notConsidered: (<>
      <h3>Factors <em>Not</em> Considered</h3>
      <p>To make your best decision possible, it's important to understand the factors this recommendation does <em>not</em> consider.</p>
      <dl>
        <dt>Average pay by language</dt>
        <dd>If making the highest possible income is the most important thing, you'll want to do some research to find out which languages and skills have the highest salaries.</dd>
        <dt>Availability of work in your area</dt>
        <dd>This can be highly localized. In some parts of the country, you need to know Javascript inside-out to even get an interview. In other places, Java will serve you better. (Yes, they're different.) If you're freelancing, it may not matter... unless it does.</dd>
        <dt>The technology stack your dream employer uses</dt>
        <dd>If your goal is to work for Google or Facebook, you'll want to be sure you focus on the languages they use. Do some research before you jump in so you don't end up with a finely honed skillset they can't (or won't) use.</dd>
      </dl>
    </>),
  };
  const buildResults = () => {
    const results = [(<h2 ref={recommendationsRef}>My Recommendations</h2>)];
    const needsFrontend = stack === 'front end' || stack === 'full stack';
    const needsBackend = stack === 'back end' || stack === 'full stack';
    if (needsFrontend) {
      results.push(resultContent.frontend);
    }
    if (needsFrontend && experience === 'Javascript') {
      results.push(resultContent.frontendJavascriptExperience);
    }
    if (stack === 'front end') {
      results.push(resultContent.xfrontend);
    }
    if (needsBackend && experience === 'Javascript') {
      results.push(resultContent.backendJavascriptExperience);
    }
    if (needsBackend && experience === '?') {
      results.push(resultContent.backendNoExperience);
    }
    if (needsBackend && experience !== '?' && experience !== 'Javascript') {
      results.push(resultContent.backendOtherExperience);
    }
    results.push(resultContent.notConsidered);
    return results;
  };
  
  return (
    <>
      <form onSubmit={handleSubmit}>
        <h2 style={{ fontSize: '2em' }}>
          What part of the technology stack do you want to work on?
        </h2>
      <div onChange={handleChange}>
        <label className='field'>
          <input type='radio' name='stack' value='front end' />
          <span className='label'>front end</span>
        </label>
        <label className='field'>
          <input type='radio' name='stack' value='back end' />
          <span className='label'>back end</span>
        </label>
        <label className='field'>
          <input type='radio' name='stack' value='full stack' />
          <span className='label'>full stack</span>
        </label>
        <label className='field'>
          <input type='radio' name='stack' value='?' />
          <span className='label'>I don't know</span>
        </label>
      </div>

        <h2 style={{ fontSize: '2em' }}>
          Why do you want to learn web development?
        </h2>
      <div onChange={handleChange}>
        <label className='field'>
          <input type='radio' name='why' value='change careers' />
          <span className='label'>change careers</span>
        </label>
        <label className='field'>
          <input type='radio' name='why' value='freelance or do contract work' />
          <span className='label'>freelance or do contract work</span>
        </label>
        <label className='field'>
          <input type='radio' name='why' value='build a startup' />
          <span className='label'>build a startup</span>
        </label>
        <label className='field'>
          <input type='radio' name='why' value='work on personal projects' />
          <span className='label'>work on personal projects</span>
        </label>
      </div>

        <h2 style={{ fontSize: '2em' }}>
          Do you have experience with any language?
        </h2>
        <p>
          If you have experience with more than one of these, select the one you
          prefer or have the most experience with. If you have experience with
          languages but don't like them, select the "No prior experience" option,
          and I won't consider it for this recommendation.
        </p>
      <div onChange={handleChange}>
        <label className='field'>
          <input type='radio' name='experience' value='?' />
          <span className='label'>no prior experience</span>
        </label>
        <label className='field'>
          <input type='radio' name='experience' value='Javascript' />
          <span className='label'>Javascript</span>
        </label>
        <label className='field'>
          <input type='radio' name='experience' value='PHP' />
          <span className='label'>PHP</span>
        </label>
        <label className='field'>
          <input type='radio' name='experience' value='Python' />
          <span className='label'>Python</span>
        </label>
        <label className='field'>
          <input type='radio' name='experience' value='Ruby' />
          <span className='label'>Ruby</span>
        </label>
        <label className='field -inline'>
          <input type='radio' name='experience' value='other' />
          <span className='label'>Other</span>
        </label>
        <label className='field -inline'>
          <input type='text' name='other-experience' disabled={experience !== 'other'} value={otherExperience} style={{paddingTop: 'var(--rhythm-eighth)'}}/>
          <span className='label sr-only'>Language</span>
        </label>
      </div>

        <input type='submit' className='button' disabled={!canSubmit()}  value='Get Recommendations' />
      </form>
      {showResults ? 
        buildResults()
       : null }
    </>
  );
};

export default LanguageQuiz;
